/* global document */

import * as msal_helper from "./msal_helper";

const docUserButton = document.getElementById("user_button");
const docHeaderRight = document.getElementById("header-right");
const docUserMenu: HTMLSelectElement = document.getElementById("user-menu") as HTMLSelectElement;

export function header_right_show(loginStatus) {
  //Right header section
  docHeaderRight.style.display = "flex";
  //console.log(loginStatus.user.name + " (" + loginStatus.user.username + ")");
  docUserButton.innerHTML = loginStatus.user.name + " (" + loginStatus.user.username + ")";
  docUserMenu.onchange = () => {
    switch (docUserMenu.value) {
      case "change user":
        msal_helper.changeUser("other");
        break;
      case "sign out":
        msal_helper.signOut();
        break;
      default:
    }
  };
}
