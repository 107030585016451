/* global console, fetch */
import * as msal from "./msal_helper";
import { API_URL } from "../../helpers/fallback-msal/authConfig.js";

export let myUsers = {};

export async function apiInterface(method, path, retryRequest = false) {
  let response;
  try {
    response = await fetch(path, {
      method: method,
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (err) {
    console.log(err);
  }
  if (response.ok) {
    return response.json();
  } else if (retryRequest) {
    response = apiInterface(method, path, false);
  }
}

export async function getUsers() {
  let apiUrl;
  let accessToken = null;
  let userInfo;
  if (myUsers.myUserData !== undefined && myUsers.error === undefined) {
    //console.log("users from cache");
    return myUsers;
  } else {
    //console.log("get users from api");
  }
  if (API_URL === "localhost") {
    //console.log("getUsers Localhost");
    apiUrl = "https://localhost:3001/powerDropdown";
  } else {
    //console.log("getUsers Web");
    apiUrl = "https://api.callmelazy.eu/powerDropdown";
  }
  //console.log(apiUrl);
  //console.log("Account ID: " + msal.homeAccountId);
  if (msal.homeAccountId !== null) {
    try {
      accessToken = await msal.getAccessToken();
      //console.log("Access token: " + JSON.stringify(accessToken));
    } catch (err) {
      console.log(err);
    }
  } else {
    return null;
  }
  if (accessToken != null) {
    try {
      //console.log(apiUrl);
      let response = await fetch(apiUrl + "/getUsers", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      });
      userInfo = await response.json();
      myUsers = userInfo;
      //console.log("User info: " + JSON.stringify(userInfo));
      return userInfo;
    } catch (err) {
      console.warn(JSON.stringify(err));
    }
  } else {
    msal.unsetHomeAccount;
    return undefined;
  }
}

export async function setOption(request) {
  let apiUrl;
  let accessToken = null;
  if (API_URL == "localhost") {
    console.log("getUsers Localhost");
    apiUrl = "https://localhost:3001/powerDropdown";
  } else {
    console.log("getUsers Web");
    apiUrl = "https://api.callmelazy.eu/powerDropdown";
  }
  if (msal.homeAccountId !== null) {
    try {
      accessToken = await msal.getAccessToken();
    } catch (err) {
      console.log(err);
    }
  } else {
    return null;
  }
  if (accessToken != null) {
    try {
      //console.log(apiUrl);
      let response = await fetch(apiUrl + "/setOptions", {
        method: "POST",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
        body: JSON.stringify({
          tenant: request.tenant,
          user: request.user,
          settings: [{ setting: request.setting, value: request.value }],
        }),
      });
      switch (response.status) {
        case 204:
          //console.log(myUsers.users.length);
          if (request.user !== undefined) {
            myUsers.users.forEach((element) => {
              //console.log(element.user_id_txt);
              if (request.user === element.user_id_txt) {
                //console.log(JSON.stringify(element));
                element[request.setting] = request.value;
                //console.log(JSON.stringify(element));
              }
            });
          }
          if (request.user === undefined) {
            //this means a tenant setting was updated
            if (request.tenant.org_id_txt === myUsers.tenant.org_id_txt) {
              myUsers.tenant[request.setting] = request.value;
            }
          }
          return { status: response.status };
        case 401:
          return { status: response.status, type: await response.json() };
        default:
          return { status: response.status, type: "Unexpected result" };
      }
    } catch (err) {
      console.warn(JSON.stringify(err));
      return { status: "error", type: JSON.stringify(err) };
    }
  }
}

export async function fulfilment(token, accessToken) {
  let apiUrl;
  if (API_URL == "localhost") {
    console.log("getUsers Localhost");
    apiUrl = "https://localhost:3001/powerDropdown";
  } else {
    console.log("getUsers Web");
    apiUrl = "https://api.callmelazy.eu/powerDropdown";
  }
  try {
    let response = await fetch(apiUrl + "/fulfilment", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
      body: JSON.stringify({
        token: token,
      }),
    });
    let result;
    switch (response.status) {
      case 200:
        console.log(response.status);
        result = await response.json();
        return { status: response.status, ...result };
      default:
        return { status: response.status };
    }
  } catch (err) {
    console.log(err);
  }
}

export async function changeQuantity(subscriptionId, additionalQuantity) {
  console.log(additionalQuantity + " " + subscriptionId);
  let apiUrl;
  let accessToken;
  if (API_URL == "localhost") {
    apiUrl = "https://localhost:3001/powerDropdown";
  } else {
    console.log("getUsers Web");
    apiUrl = "https://api.callmelazy.eu/powerDropdown";
  }
  if (msal.homeAccountId !== null) {
    try {
      accessToken = await msal.getAccessToken();
    } catch (err) {
      console.log(err);
    }
  } else {
    return null;
  }
  try {
    let response = await fetch(apiUrl + "/changeQty", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
      body: JSON.stringify({
        subscriptionId: subscriptionId,
        addQty: additionalQuantity,
      }),
    });
    console.log(response.status);
    switch (response.status) {
      case 200:
        console.log(response.status);
        return { status: response.status };
      default:
        return { status: response.status };
    }
  } catch (err) {
    console.log(err);
  }
}

export async function buyLicence(products, transactionResponse) {
  let apiUrl;
  let accessToken = null;
  if (API_URL === "localhost") {
    console.log("buyLience Localhost");
    apiUrl = "https://localhost:3001/powerDropdown";
  } else {
    console.log("buyLicence Web");
    apiUrl = "https://api.callmelazy.eu/powerDropdown";
  }
  if (msal.homeAccountId !== null) {
    try {
      accessToken = await msal.getAccessToken();
    } catch (err) {
      console.log(err);
    }
  } else {
    return null;
  }
  let body = JSON.stringify({
    products: products,
    vivaResult: transactionResponse,
  });
  if (accessToken != null) {
    if (transactionResponse !== undefined || products !== undefined) {
      try {
        let response = await fetch(apiUrl + "/buyLicence", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
          body: body,
        });
        if (response.ok) {
          let result = await response.json();
          return result;
        } else {
          return { status: "failed" };
        }
      } catch (err) {
        console.warn(JSON.stringify(err));
        return { status: JSON.stringify(err) };
      }
    }
  } else {
    msal.unsetHomeAccount;
    return undefined;
  }
}

export async function getBuyOptions() {
  let apiUrl;
  let accessToken = null;
  if (API_URL === "localhost") {
    console.log("getBuyOptions Localhost");
    apiUrl = "https://localhost:3001/powerDropdown";
  } else {
    console.log("getBuyOptions Web");
    apiUrl = "https://api.callmelazy.eu/powerDropdown";
  }
  if (msal.homeAccountId !== null) {
    try {
      accessToken = await msal.getAccessToken();
    } catch (err) {
      console.log(err);
    }
  } else {
    return null;
  }
  if (accessToken != null) {
    try {
      let response = await fetch(apiUrl + "/buyLicence", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      });
      if (response.ok) {
        let result = await response.json();
        return result;
      } else {
        return { status: "failed" };
      }
    } catch (err) {
      console.warn(JSON.stringify(err));
    }
  } else {
    msal.unsetHomeAccount;
    return undefined;
  }
}

export default apiInterface;
