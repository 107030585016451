// eslint-disable-next-line no-redeclare, @typescript-eslint/no-unused-vars
/* global console */

import * as docApp from "../taskpane/docElements";
//import * as docWeb from "../Website/Common/docElements";

export function licenceMessage(PDlicence: number) {
  switch (PDlicence) {
    case 0:
      return "No licence available";
    case 1:
      return "Standard licence";
    case 2:
      return "Premium licence";
    case 10:
      return "Trial licence";
    default:
      return "Licence error";
  }
}

export function setAppControls(PDLicence: number) {
  docApp.Message.innerHTML = licenceMessage(PDLicence);
  switch (PDLicence) {
    case 0:
      docApp.ManageLicence.innerHTML = "Purchase licence";
      docApp.ManageLicence.style.display = "flex";
      docApp.SwActivate.disabled = true;
      docApp.Verify.style.display = "none";
      docApp.AppBody.style.display = "none";
      docApp.SideloadMessage.style.display = "flex";
      docApp.btnRetry.style.display = "none";
      break;
    case 1:
      docApp.ManageLicence.innerHTML = "Manage licence";
      docApp.ManageLicence.style.display = "flex";
      docApp.SwActivate.disabled = false;
      docApp.Verify.style.display = "none";
      docApp.AppBody.style.display = "flex";
      docApp.SideloadMessage.style.display = "none";
      docApp.btnRetry.style.display = "none";
      break;
    case 2:
      docApp.ManageLicence.innerHTML = "Manage licence";
      docApp.ManageLicence.style.display = "none";
      docApp.SwActivate.disabled = false;
      docApp.Verify.style.display = "flex";
      docApp.AppBody.style.display = "flex";
      docApp.SideloadMessage.style.display = "none";
      docApp.btnRetry.style.display = "none";
      break;
    case 10:
      docApp.ManageLicence.innerHTML = "Purchase licence";
      docApp.ManageLicence.style.display = "flex";
      docApp.SwActivate.disabled = false;
      docApp.Verify.style.display = "flex";
      docApp.AppBody.style.display = "flex";
      docApp.SideloadMessage.style.display = "none";
      docApp.btnRetry.style.display = "none";
      break;
    default:
      docApp.btnRetry.style.display = "flex";
      console.log("Licence error");
  }
}
