// eslint-disable-next-line @typescript-eslint/no-unused-vars
/* global screen, console, window, setTimeout */

import * as doc from "./docElementsSite2";
import * as msal_helper from "./msal_helper";

export async function adaptToScreen(loginSection?: boolean) {
  doc.header.style.display = "flex";
  doc.footer.style.display = "flex";
  doc.main.style.display = "flex";
  if (screen.width < 680) {
    doc.headerTitle.style.display = "none";
    doc.cml.style.display = "none";
    doc.navigation.style.display = "none";
    doc.navFlipper.style.display = "flex";
    doc.navFlipper.onclick = () => {
      if (doc.navigation.style.display === "none") {
        doc.navigation.style.display = "flex";
        doc.navigation.onclick = () => {
          doc.navigation.style.display = "none";
        };
      } else {
        doc.navigation.style.display = "none";
      }
    };
  } else {
    doc.headerTitle.style.display = "flex";
    doc.navigation.style.display = "flex";
    doc.navFlipper.style.display = "none";
  }
  if (screen.height < 680) {
    //doc.contents.style.display = "none";
  } else {
    //doc.contents.style.display = "flex";
  }
  //Set actions on top right menu items
  doc.changeUser.onclick = () => msal_helper.changeUser("other");
  doc.signOut.onclick = () => msal_helper.signOut();
  //Set buttons to logged off state until we understand the actual state
  doc.headerUserButton.textContent = "Sign in";
  doc.headerUserButton.style.width = "80px";
  //Set front end options depending on login status
  const loginStatus: any = await msal_helper.loginStatus();
  if (loginStatus.status === "logged on") {
    doc.navHome.style.display = "block";
    doc.navLicMgmt.style.display = "block";
    doc.headerUserButton.style.width = "50px";
    const userName: string = loginStatus.user.name;
    const userEmail: string = loginStatus.user.username;
    const initials: string = userName.split(" ")[0].charAt(0) + userName.split(" ")[1].charAt(0);
    doc.userButton.innerHTML = userName + "<br>" + userEmail;
    doc.headerUserButton.textContent = initials;
    doc.headerUserButton.onclick = () => {
      if (doc.userMenu.style.display !== "flex") {
        doc.userMenu.style.display = "flex";
        doc.main.onclick = () => {
          doc.userMenu.style.display = "none";
          doc.main.onclick = undefined;
        };
      } else {
        doc.userMenu.style.display = "none";
        doc.main.onclick = undefined;
      }
    };
  } else if (loginStatus.status === "logged off") {
    if (loginSection) {
      doc.main.style.display = "none";
      doc.header.style.display = "none";
      doc.footer.style.display = "none";
      doc.loginSection.style.display = "flex";
      doc.loginButton.onclick = () => msal_helper.signIn();
    } else {
      doc.headerUserButton.textContent = "Sign in";
      doc.headerUserButton.style.width = "80px";
      doc.headerUserButton.onclick = () => msal_helper.signIn(false, "help.html");
    }
  } else if (loginStatus.status === "selecting user") {
    //This is not tested yet, currently handled in each page
  }
  return loginStatus;
}
