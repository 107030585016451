// This file copied and modified from https://github.com/Azure-Samples/ms-identity-javascript-tutorial/blob/main/1-Authentication/1-sign-in/App/authConfig.js
//import { LogLevel, PublicClientApplication } from "@azure/msal-browser";
import { LogLevel } from "@azure/msal-browser";
/* global console */
/* ************Change here for LOCALHOST API*************** */
import { API_URL } from "../../helpers/fallback-msal/authConfig.js";
//const clientId = "0220c7d8-4bc4-423c-a679-2caca7645471";
//const accessScope = "api://" + window.location.host + "/" + clientId + "/access_identity_as_user";
let accessScope;
let myRedirectUri;
let clientId;
if (API_URL == "localhost") {
  clientId = "66b46f6f-fc3d-4e86-b707-3cef7e2f161a";
  accessScope = "api://" + "localhost:3000" + "/" + clientId + "/access_identity_as_user";
  myRedirectUri = "https://localhost:3000/";
  //  accessScope = "api://" + "api.callmelazy.eu" + "/" + clientId + "/access_identity_as_user";
  //  myRedirectUri = "https://powerdropdown.callmelazy.eu/fallback.html";
} else {
  clientId = "6e909ab9-a58d-45f3-adb7-ec7ba577f28e";
  accessScope = "api://" + "powerdropdown.callmelazy.eu" + "/" + clientId + "/access_identity_as_user";
  myRedirectUri = "https://powerdropdown.callmelazy.eu/";
}
//const accessScope = "api://localhost:3001/6e909ab9-a58d-45f3-adb7-ec7ba577f28e/access_identity_as_user";
/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://learn.microsoft.com/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 *
 */
export const loginRequest = {
  scopes: [accessScope],
  extraScopesToConsent: ["user.read"],
};

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */

export const msalConfig = {
  auth: {
    clientId: clientId, // This is the ONLY mandatory field that you need to supply.
    authority: "https://login.microsoftonline.com/common", // Defaults to "https://login.microsoftonline.com/common"
    redirectUri: myRedirectUri, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.href
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
    response_type: "access_token",
  },
  cache: {
    cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO.
    storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
  },
  prompt: "select_account",
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            //console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

//export const myMSALObj = new PublicClientApplication(msalConfig);

// exporting config object for jest
//if (typeof exports !== "undefined") {
//  module.exports = {
//    msalConfig: msalConfig,
//  };
//}
