import * as doc from "../Common/docElementsSite2";

export function getCurrencySymbol(currency: string) {
  switch (currency) {
    case "EUR":
      return "€";
    case "USD":
      return "$";
    default:
      return "€";
  }
}

export function selectUser(currentAccounts) {
  //console.log(JSON.stringify(currentAccounts));
  doc.selectUser.hidden = false;
  let strUsers: string = "";
  currentAccounts.forEach((element) => {
    //console.log(JSON.stringify(element.name));
    strUsers += `<fluent-option class="select-user" value="${element.homeAccountId}">${element.name} (${element.username})</fluent-option>`;
  });
  strUsers += `<fluent-option value="other">Select another user</fluent-option>`;
  //docUserList.title = "None";
  doc.userList.size = currentAccounts.length + 1;
  doc.userList.innerHTML = strUsers;
  return;
}
