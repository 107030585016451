// eslint-disable-next-line @typescript-eslint/no-unused-vars
/* global document, window, screen, console */
/* global HTMLButtonElement, HTMLTableElement, HTMLTableRowElement, HTMLTableCellElement, URLSearchParams */
//import * as msal_helper from "../Common/msal_helper";
//import * as header_helper from "../Common/header_helper";
import * as visual from "../Common/visualization2";
import * as doc from "../Common/docElementsSite2";
import * as msal_helper from "../Common/msal_helper";
import * as helpers from "../Common/helpers";
import * as api from "../Common/apiInterface";
import { licenceMessage } from "../../helpers/licence_helper";

import { fluentMenu, fluentMenuItem, provideFluentDesignSystem } from "@fluentui/web-components";

provideFluentDesignSystem().register(fluentMenu(), fluentMenuItem);

//document elements available only on this page
const docWelcomeMessage = document.getElementById("welcome-message");
const docWelcomeMessage2 = document.getElementById("welcome-message2");
const docLicenceMessage = document.getElementById("licence-message");
const docLicMgmtBtn = document.getElementById("licencemgmt-button");
const cmlButton = document.getElementById("cml-button");
const docBuySection = document.getElementById("buysection");
const paymentMethods = document.getElementById("payment-methods");
const eula = document.getElementById("eula");
const eula_section = document.getElementById("eula-section");
const docBuyNow = document.getElementById("buynow");

const loginSection: boolean = true;

window.addEventListener("DOMContentLoaded", async () => {
  console.log("DOM loaded-Root");
  const loginStatus: any = await visual.adaptToScreen(loginSection);
  screen.orientation.onchange = () => {
    visual.adaptToScreen(loginSection);
  };
  if (loginStatus.status === "logged on") {
    loggedOn(loginStatus);
  } else if (loginStatus.status === "selecting user") {
    console.log("user selection");
    helpers.selectUser(loginStatus.user);
    doc.userList.onchange = async () => {
      let loginStatus = await msal_helper.changeUser(doc.userList.value);
      loggedOn(loginStatus);
    };
  } else {
    console.log("logged off");
    //window.location.href = "/";
  }
});

async function loggedOn(loginStatus) {
  //console.log(JSON.stringify(myUsers));
  doc.mainContentCentered.style.display = "flex";
  doc.loading.style.display = "flex";
  let myUsers = await api.getUsers();
  doc.loading.style.display = "none";
  const searchParams = new URLSearchParams(window.location.search);
  if (searchParams.has("t") && searchParams.has("s")) {
    const transactionResponse = {
      tid: searchParams.get("t"),
      s: searchParams.get("s"),
      eventId: searchParams.get("eventid"),
      eci: searchParams.get("eci"),
      lang: searchParams.get("lang"),
    };
    await buyLicence(transactionResponse);
    //console.log(response);
    window.location.href = "/";
  }
  if (myUsers.myUserData !== undefined) {
    docWelcomeMessage.style.display = "flex";
    docWelcomeMessage.textContent = `Welcome ${loginStatus.user.name}`;
    let strMessage1 = "You are signed in as ";
    let strMessage2 = "";
    let PDlicence: number;
    PDlicence = myUsers.myLicence.PDlicence;
    docLicenceMessage.innerHTML = "Active licence: " + licenceMessage(PDlicence);
    switch (myUsers.myUserData.isAdmin) {
      case 1:
        strMessage1 += "an Administrator";
        docLicMgmtBtn.style.display = "flex";
        break;
      default:
        docLicMgmtBtn.style.display = "none";
        strMessage1 += "a User";
        break;
    }
    switch (myUsers.myUserData.isLazy) {
      case 1:
        cmlButton.style.display = "flex";
        break;
      default:
        cmlButton.style.display = "none";
        break;
    }
    switch (myUsers.tenant.Plan) {
      case "Site":
      case "Seat":
        strMessage2 += `Your organization has a ${myUsers.tenant.Plan} based licence.`;
        break;
      default:
        strMessage2 += `No organization licence is available.`;
        docLicMgmtBtn.style.display = "none";
        break;
    }
    docWelcomeMessage2.innerHTML = `${strMessage1}. ${strMessage2}`;
    docWelcomeMessage2.style.display = "flex";
    docWelcomeMessage.hidden = false;
    //doc.docLicMgmtBtn.hidden = false;
    //
    doc.selectUser.style.display = "none";
    //Right header section - now handled elsewhere
    //header_helper.header_right_show(loginStatus);
    //Buy Licence section
    if (myUsers.offerings === undefined || myUsers.offerings.error !== undefined) {
      docBuySection.style.display = "none";
      return;
    }
    if (myUsers.offerings.length !== 0) {
      const buyOptions = myUsers;
      docBuySection.style.display = "flex";
      paymentMethods.style.display = "flex";
      eula_section.style.display = "none";
      eula.style.display = "flex";
      eula.innerHTML = buyOptions.eula;
      //let table: DataGrid = document.createElement("fluent-data-grid") as DataGrid;
      //table.rowsData = buyOptions;
      //doc.docBuySection.appendChild(table);
      const extendTable = createActiveLicenceTable(buyOptions.offerings, PDlicence, myUsers.myUserData);
      if (extendTable !== undefined) docBuySection.appendChild(extendTable);
      const addTable: HTMLTableElement = createBuyTable(buyOptions);
      if (addTable !== undefined) docBuySection.appendChild(addTable);
    } else {
      docBuySection.style.display = "none";
    }
  }
}

async function buyLicence(transactionResponse?) {
  console.log(JSON.stringify(transactionResponse));
  if (transactionResponse.s === undefined) {
    transactionResponse = undefined;
  }
  try {
    let products: {
      productCode: any;
      buyType: any;
      qty: number;
      discountPercent: number;
      extendedPrice: number;
      currency: string;
    }[];
    if (transactionResponse === undefined) {
      const product = JSON.parse(this.value);
      //const discountPercent = 0.1;
      products = [
        {
          productCode: product.productCode,
          buyType: product.buyType,
          qty: product.qty,
          discountPercent: product.discountPercent,
          extendedPrice: product.extendedPrice,
          currency: product.currency,
        },
      ];
      console.log("buy: " + JSON.stringify(products));
      docBuySection.style.display = "none";
      docBuyNow.appendChild(createAgreeButton(products));
      eula_section.style.display = "flex";
      docBuyNow.style.display = "flex";
      return;
    } else {
      await api.buyLicence(products, transactionResponse);
    }
  } catch (err) {
    console.warn(JSON.stringify(err));
  }
}

function createActiveLicenceTable(
  buyOptions: [
    {
      buyType: string;
      friendlyName: string;
      productCode: string;
      unitPrice: string;
      extendedPrice: string;
      currency: string;
    },
  ],
  PDlicence: number,
  myUserData: { lic_expiration: string }
) {
  let extendOption: {
    buyType: string;
    friendlyName: string;
    productCode: string;
    unitPrice: string;
    extendedPrice: string;
    currency: string;
  };
  buyOptions.forEach((buyOption) => {
    if (buyOption.buyType === "extend") {
      extendOption = buyOption;
      return;
    }
  });
  if (extendOption === undefined) return undefined;
  let table: HTMLTableElement = document.createElement("table") as HTMLTableElement;
  table.id = "active-table";
  table.className = "licence-table";
  let header: HTMLTableRowElement = document.createElement("tr") as HTMLTableRowElement;
  header.innerHTML = "<th>Active licence</th><th>Expiration</th><th>Extend by a year</th>";
  let expDate = new Date(Date.parse(myUserData.lic_expiration));
  let button: HTMLButtonElement = createBuyButton(extendOption);
  let buttonCell: HTMLTableCellElement = document.createElement("td") as HTMLTableCellElement;
  buttonCell.appendChild(button);
  buttonCell.className = "licence-table-button";
  let rowActive: HTMLTableRowElement = document.createElement("tr") as HTMLTableRowElement;
  rowActive.innerHTML = `<td>${licenceMessage(PDlicence)}</td><td>${expDate.toDateString()}</td>`;
  rowActive.appendChild(buttonCell);
  table.appendChild(header);
  table.appendChild(rowActive);
  return table;
}

function createBuyTable(buyOptions: {
  offerings: {
    buyType: string;
    friendlyName: string;
    productCode: string;
    unitPrice: string;
    extendedPrice: string;
    currency: string;
    term: string;
    qty: number;
  }[];
  eula: string;
}) {
  let table: HTMLTableElement = document.createElement("table") as HTMLTableElement;
  table.id = "buy-table";
  table.className = "licence-table";
  let header: HTMLTableRowElement = document.createElement("tr") as HTMLTableRowElement;
  header.innerHTML = "<th>Description</th><th>Cost</th><th>Term</th><th>Purchase</th>";
  table.appendChild(header);
  let rowActive: HTMLTableRowElement = document.createElement("tr") as HTMLTableRowElement;
  rowActive.innerHTML = `<td>Active licence: </td>`;
  buyOptions.offerings.forEach((offering) => {
    if (offering.buyType !== "extend") {
      let row: HTMLTableRowElement = document.createElement("tr") as HTMLTableRowElement;
      row.innerHTML = `<td>${offering.friendlyName}</td><td>${offering.extendedPrice}${helpers.getCurrencySymbol(
        offering.currency
      )}</td>
      <td>${offering.term}</td>`;
      let cell: HTMLTableCellElement = document.createElement("td") as HTMLTableCellElement;
      let button: HTMLButtonElement = createBuyButton(offering);
      cell.appendChild(button);
      row.appendChild(cell);
      table.appendChild(row);
    }
  });
  if (table.rows.length > 1) {
    return table;
  } else {
    return undefined;
  }
}

function createAgreeButton(products: any) {
  let button: HTMLButtonElement = document.createElement("fluent-button") as HTMLButtonElement;
  button.textContent = "Agree";
  button.setAttribute("appearance", "accent");
  console.log(JSON.stringify({ action: "agreeEula", products }));
  button.value = JSON.stringify({ action: "agreeEula", products });
  button.classList.add("cmlButton");
  button.onclick = agreeEula;
  return button;
}

function createBuyButton(offering: {
  buyType: string;
  friendlyName: string;
  productCode: string;
  unitPrice: string;
  extendedPrice: string;
  currency: string;
}) {
  let button: HTMLButtonElement = document.createElement("fluent-button") as HTMLButtonElement;
  button.textContent = "Buy (" + offering.extendedPrice + helpers.getCurrencySymbol(offering.currency) + ")";
  button.setAttribute("appearance", "accent");
  button.value = JSON.stringify({
    productCode: offering.productCode,
    buyType: offering.buyType,
    extendedPrice: offering.extendedPrice,
    currency: offering.currency,
  });
  button.classList.add("cmlButton");
  button.onclick = buyLicence;
  return button;
}

async function agreeEula() {
  let buttonValue = JSON.parse(this.value);
  if (buttonValue.action === "agreeEula") {
    eula_section.style.display = "none";
    this.textContent =
      "Buy now (" +
      buttonValue.products[0].extendedPrice +
      helpers.getCurrencySymbol(buttonValue.products[0].currency) +
      ")";
    buttonValue.action = "buyNow";
    this.value = JSON.stringify(buttonValue);
    return;
  }
  if (buttonValue.action === "buyNow") {
    doc.main.style.display = "none";
    docBuyNow.style.display = "none";
  }
  try {
    let products = buttonValue.products;
    console.log("buy: " + JSON.stringify(products));
    eula_section.style.display = "none";
    //let result;
    let result = await api.buyLicence(products, undefined);
    if (result.status === "success") {
      console.log("Buy license result: " + result.status);
      console.log(JSON.stringify(result));
      window.location.href = "/";
    } else if (result.status === "redirect") {
      console.log(JSON.stringify(result));
      window.location.href = result.redirectUrl;
    } else {
      console.log(result.error);
      console.log("API request failed");
    }
  } catch (err) {
    console.warn(JSON.stringify(err));
  }
}
