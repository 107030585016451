export function getCurrencySymbol(currency: string) {
  switch (currency) {
    case "EUR":
      return "€";
    case "USD":
      return "$";
    default:
      return "€";
  }
}
