// eslint-disable-next-line no-redeclare, @typescript-eslint/no-unused-vars
/* global HTMLSelectElement, HTMLOptionElement, HTMLAnchorElement, HTMLInputElement */
/* global document */

import {
  provideFluentDesignSystem,
  fluentButton,
  fluentProgressRing,
  fluentSwitch,
  fluentOption,
  fluentSelect,
} from "@fluentui/web-components";

provideFluentDesignSystem().register(
  fluentButton(),
  fluentProgressRing(),
  fluentSwitch(),
  fluentOption(),
  fluentSelect()
);

//Login section
export const loginSection = document.getElementById("login-section");
export const loginButton = document.getElementById("login-button");

//Header Elements
export const header = document.getElementById("header");
export const cml = document.getElementById("cml");
export const headerTitle = document.getElementById("header_title");
export const headerUserButton = document.getElementById("header_user_button") as HTMLInputElement;
//Header Elements - right menu
export const userMenu = document.getElementById("user_menu");
export const userButton = document.getElementById("user_button");
export const changeUser = document.getElementById("change_user");
export const signOut = document.getElementById("sign_out");

//Footer Elements
export const footer = document.getElementById("footer");

//Main Elements
export const main = document.getElementById("main");
export const mainLeft = document.getElementById("main-left");
export const mainContentCentered = document.getElementById("content-centered");
export const mainContentLeft = document.getElementById("content-aligned-left");
export const mainContentText = document.getElementById("content-text");
//Main-Navigation
export const navigation = document.getElementById("navigation");
export const navFlipper = document.getElementById("nav-flipper");
export const navHome = document.getElementById("nav_home");
export const navLicMgmt = document.getElementById("nav_licmgmt");
//Main-select user
export const selectUser = document.getElementById("select-user");
export const userList: HTMLSelectElement = document.getElementById("user-list") as HTMLSelectElement;
//Main-...
export const loading = document.getElementById("loading");
