// eslint-disable-next-line no-redeclare, @typescript-eslint/no-unused-vars
/* global document */

import {
  provideFluentDesignSystem,
  fluentButton,
  fluentProgressRing,
  fluentSkeleton,
  fluentSwitch,
  fluentTooltip,
  Tooltip,
} from "@fluentui/web-components";

provideFluentDesignSystem().register(
  fluentButton(),
  fluentProgressRing(),
  fluentSkeleton(),
  fluentSwitch(),
  fluentTooltip()
);

export const SwActivate = document.getElementById("swActivate") as HTMLInputElement;
export const Message = document.getElementById("message");
export const SideloadMessage = document.getElementById("sideload-msg");
export const AppBody = document.getElementById("app-body");
export const Version = document.getElementById("version");
export const ManageLicence = document.getElementById("website");
export const Verify = document.getElementById("verify");
export const VerifyButton = document.getElementById("verify-button") as HTMLInputElement;
export const VerifyProgress = document.getElementById("verify-progress") as HTMLInputElement;
export const VerifyMessage = document.getElementById("verify-message") as Tooltip;
export const SliderActive = document.getElementById("activePercent") as HTMLInputElement;
export const RangeValue = document.getElementById("rangeValue");
export const Options = document.getElementById("options");
export let pdOptions: HTMLCollectionOf<Element>;
if (Options !== null) {
  pdOptions = Options.getElementsByTagName("fluent-switch");
}
export const btnRetry = document.getElementById("retrylicence") as HTMLButtonElement;
